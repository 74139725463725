.container-grid{
	font-family: 'Proxima Nova', Fallback, sans-serif;
}

/* for path selector list */
.basic-navbar-nav{
    transition: height 2s;
}

.description-container {
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.selector-header {
    border-bottom: solid rgb(185, 185, 185) 1px;
}

.description-separator {
    margin: 0;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.panel-title {
    padding: 1rem;
    padding-bottom: 0;
}

.panel-description {
    margin-bottom: 0px;
    white-space: pre-wrap;
    padding: 1rem;
}

.list-container {
    height: 100vh;
    background-color: white;
    overflow-y: scroll;
    margin-left: 0px;
    padding-left: 0px;
}

.list-container::-webkit-scrollbar {
    width: 7px;
}

.list-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.list-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.list-item-container {
    border-top: 1px solid rgb(230, 230, 230);
    background-color: white;
    border-left: 8px solid #d9c5b255;
}

.item-hovered {
    background-color: #d9c5b23b;
    border-left: 8px solid #d9c5b250;
    cursor: pointer;
}

.item-hovered:active{
    box-shadow: inset 0px 0px 25px 2px #d9c5b250;
}

.list-item-text {
    white-space: pre-wrap;
}

.item-hovered {
    background-color: rgb(236, 235, 235);
    box-shadow: .5px .25px 1px .1px grey;
}

.item-selected{
    background-color: #d9c5b273;
    box-shadow: .5px .25px 1px .1px grey;
    border-left: 8px solid #D9C5B2;
    transition: border .5s;
}

.avatar {
    /* stroke: #1976d2;
    stroke: #F2BF5E; */
    stroke: #d15b38;
    fill: #ffffff00; /* transparent */
}

/* Styles for TourContainer.tsx */
.container-grid {
    background-color: #000000;
    margin-top: 0;
    height: 100vh;
}

.svg-path-col {
    background-color: white;
    height: 100vh;
}

/* Styles for CarouselContent.tsx */
.carousel-nav {
    width: 100%;
    text-align: center;
    background-color: #1b1b1b;
    height: 45px;

}

.carousel-grid {
    display: grid;
    grid-template-columns: 1fr 12fr 1fr;
    grid-gap: 0px;
    padding-top: 2.5px;
    justify-content: center;
    text-align: center;
}

.slider-btn-col {
    height: 90vh;
}

.slider-col {
    height: 90vh;
    display: grid;
    justify-content: center;
    border: 1px solid blue;
    /* gap: 5px; */
}

.zoom {
    width: 100%;
    background-color: #000;
    border: 5px solid #000;
    user-select: none;
    /* add zoom translate/transform here */
}

.reset-zoom-button {
    position: fixed !important;
    top: 52px;
    left: 5px;
    font-size: 12px;
    color: black !important;
    background-color: rgba(255, 255, 255, 0.671) !important;
    border: 1px solid white !important;
}

.reset-zoom-button:hover{
    background-color: white !important;
}

.slider {
    margin-left: auto;
    margin-right: auto;
    height: 90vh;
  }

.headline {
    text-align: center;
    color: black;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
    padding-left: 27%;
    padding-right: 27%;
}

.carousel__slide {
    justify-content: center;
    display: grid;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
    background-color: rgba(49, 49, 49, 0.411);
    position: relative;
}

.panel-nav {
    padding-bottom: .4em;
    padding-top: .5em;
    font-size: 90%;
}

.panel-nav .icon {
    display: none;
  }

.panel-nav-toggle-name {
    display: none;
    margin-left: 1em;
    font-size: 2px;
}

.navbar-collapse {
    background-color: white;
    z-index: 3;
    background-color: #1b1b1b;
}

.panel-toggle-box {
    margin-top: -5px;
}

.panel-toggle-box span {
    font-size: 15px;
}

.dot-btn {
    background-color: transparent;
    border: none;
    color: rgb(156, 156, 156);
    margin-left: 1em;
    margin-right: 1em;
}

.dot-btn:hover {
    color: rgb(199, 199, 199);
}

.dot-btn-active {
    color: white;
    border-bottom: solid white 1px;
    font-weight: bold;
}

.info-icon{
    color: #1976d2;
}

.btn-next-back-container {
    position: relative;
    text-align:center;
}

.slider-btn {
    background-color: white;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block;
    background-color: #1976d250;
    color: white;
    border: 2px solid gray;
    transition: 0.4s;
    top: 50%;
    position: relative;
}

.slider-btn:hover {
    border: 3px solid rgb(177, 240, 240);
}

.slider-btn-next:active {
    border: 3px solid white;
    padding-right: 1px;
}

.slider-btn-back:active {
    border: 3px solid white;
    padding-left: 1px;
}

.align-right {
    text-align: right;
}

/* SVG Overlay */
.slider-img {
    height: 90vh;
    width: auto;
    background: black;
}

.svg-image {
    transition: transform 1s linear 0.5s;
}

.path {
    transition: transform 1s linear 0.5s;
}

.path-hovered {
    stroke: #00FFEE;
    stroke-width: 7;
    fill: #ffffff00; /* transparent */
    cursor: pointer;
}

.path-no-hovered {
    stroke: #ffffff00; /* transparent */
    stroke-width: 0;
    fill: #ffffff00; /* transparent */
}

.path-selected {
    stroke: #39FF14;
    stroke-width: 7;
    fill: #ffffff00; /* transparent */
}

.path-zoomed {
    stroke: #00FFEE;
    stroke-width: 5;
}

.details-text {
    display: none;
}

.details-text:hover {
    color:rgb(133, 133, 133);
    cursor: pointer;
}

@media only screen and (max-width: 1400px) {
    .slider {
        max-width: 350px;
    }

    .carousel__slider {
        padding-left: 0px;
        padding-right: 0px;
    }

    .slider-img-hidden {
        display: none;
    }
}

/* Mobile Screens width less than 1000px*/
@media only screen and (max-width: 1150px) {
    .dot-btn {
        margin-left: .6em;
        margin-right: .6em;
    }

      .slider-btn {
        padding: 1px;
        margin: 8px;
        width: 50px;
        height: 50px;
    }
}

/* Mobile Screens width less than 992px. This corresponds
to the react navbar toggle menu*/
@media only screen and (max-width: 992px) {
    .dot-btn-active {
        border: none;
    }

    .panel-nav-toggle-name {
        display: block;
    }
}

/* Mobile Screens width less than 900px*/
@media only screen and (max-width: 900px) {
    .slider-btn {
        position: fixed;
        top: 5px;
        border-radius: 5px;
        height: 35px;
        width: 55px;
        margin: 0;
        z-index: 2;
        background-color: #ffffff00;
        transition: top 1s;
    }

    .slider-btn-back{
        left: 2px;
        margin-right: 10px;
    }

    .slider-btn-next{
        margin-left: 10px;
        right: 2px;
    }

    .svg-path-col {
        background-color: #ffffff;
        opacity: .9;
        width: 100vw;
        text-align: center;
        position: fixed;
        padding-top: .6vh;
        bottom: 0;
        font-size: 16px;
        transition: height 1s;
        display: block;
    }

    .details-text {
        display: block;
    }

    .description-container {
        height: 40vh;
    }

    .selector-header {
        padding-top: 0;
        margin-top: 0;
    }

    .selector-header *{
        display: inline-block;
    }

    .selector-header h3{
        font-size: 150%;
    }

    .selector-header p{
        font-size: 90%;
    }

    .description-separator {
        display: block;
    }

    .list-container {
        height: unset;
        overflow-y: unset;
    }

    .panel-description {
        padding-bottom: 0;
    }

    .panel-title {
        padding: 0.5rem;
        padding-bottom: 0;
    }

    .reset-zoom-button {
        /* top: 10px; */
        left: 50%;
        transform: translateX(-50%);    
        font-size: 12px !important;
    }
}

/* Mobile Screens width less than 600px*/
@media only screen and (max-width: 600px) {

}

/* Adjusts the carousel when the screen height is less than 550px */
@media only screen and (max-height: 550px) {
    .carousel__slider {
        padding-left: 0px;
        padding-right: 0px;
    }

    .selector-header {
        height: 85px;
    }

    .details-text {
        font-size: 12px;
        padding-top: 0px;
    }
}
